import styled from 'styled-components'

export const Card = styled.div`
  position: relative;
  grid-column: span 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 100%;
  background-color: ${props => props.theme.colors.platform};
  padding: calc(${props => props.theme.sizes.edge} * 1);
  padding-bottom: 0;
  border-radius: 6px;
`

export const Title = styled.p`
  font-size: 12pt;
  font-weight: 700;
  color: ${props => props.theme.colors.dark};
  margin: 0;
  padding-bottom: ${props => props.theme.sizes.edge};
`

export const Description = styled.p`
  flex: 1;
  font-size: 10pt;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.contentMedium};
  margin: 0;
  min-height: 56px;
  padding-bottom: ${props => props.theme.sizes.edge};
`

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  width: 100%;
  position: relative;
  background-color: ${props => props.theme.colors.white};
  min-height: 54px;
  border-radius: 8px;
  cursor: pointer;
`

export const Text = styled.div`
  color: ${props => props.theme.colors.dark};
  font-size: 11pt;
  font-weight: 500;
  opacity: 1;
  margin: 0;
  padding: 0 ${props => props.theme.sizes.edge};
`

export const Content = styled.div`
  width: calc(100% + 2px);
  margin-left: -1px;
  p,
  span,
  b,
  div,
  input,
  input::placeholder {
    font-weight: 600;
    font-size: 11pt !important;
  }
`
